'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:UserContextManager

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'UserContextManager', [
    'MundoDefaultManager'
    'UserManager'
    'UserRoleManager'
    'TenantManager'
    'UserContextGroupManager'
    (
      MundoDefaultManager
      UserManager
      UserRoleManager
      TenantManager
      UserContextGroupManager
    ) ->
      UserContextManagerBase  = new MundoDefaultManager()

      UserContextManagerBase.setUrl('user_contexts')
      UserContextManagerBase.setNewObject(['user', 'roles', 'userContextGroups', 'tenant'])
      UserContextManagerBase.setSearchFields(['user.username'])

      UserContextManagerBase.new = (data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        if (data.userContextGroups)
          data.userContextGroups = @extractIds(data.userContextGroups)

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()))

      UserContextManagerBase.update = (id, data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        if (data.userContextGroups)
          data.userContextGroups = @extractIds(data.userContextGroups)

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      UserContextManagerBase.getNewForm = ->
        [
          key: 'user'
          name: 'user'
          type: 'select'
          templateOptions:
            label: 'User'
            placeholder: 'User'
            labelProp: "username"
            valueProp: "id"
            options: UserManager.getFullList().$object
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          templateOptions:
            label: 'User Context Groups'
            placeholder: 'User Context Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user context group'
            labelProp: "label"
            valueProp: "id"
            options: UserContextGroupManager.getList().$object
        ,
          key: 'tenant'
          name: 'tenant'
          type: 'select'
          templateOptions:
            label: 'Tenant'
            placeholder: 'tenant'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: TenantManager.getFullList().$object
        ]

      UserContextManagerBase.getEditForm = (data) ->
        [
          key: 'user'
          name: 'user'
          type: 'select'
          defaultValue: data.user.id
          templateOptions:
            label: 'User'
            placeholder: 'User'
            labelProp: "username"
            valueProp: "id"
            options: UserManager.getFullList().$object
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          defaultValue: data.roles
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          defaultValue: data.userContextGroups
          templateOptions:
            label: 'User Context Groups'
            placeholder: 'User Context Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user context group'
            labelProp: "label"
            valueProp: "id"
            options: UserContextGroupManager.getList().$object
        ]

      UserContextManagerBase.getListSchema = ->
        [
          key: 'user.username'
          title: 'datatable.label.user'
  #        sort: 'user.username'
        ,
          key: 'tenant.label'
          title: 'datatable.label.tenant'
          sort: 'tenant.label'
        ,
          key: 'roles.label'
          title: 'datatable.label.roles'
          multiple: 'roles'
          hideInList: true

        ]

      #permission needed to edit/delete an entity
      UserContextManagerBase.editPermission = 'manage all MundoMosaUserBundle:UserContext entities'

      UserContextManagerBase
  ]
